import React from "react";
import "./InfoCardShort.scss";

export default () => (
  <div className="page-info">
    <h2>Fáilte!</h2>
    <p>
      Witaj na stronie grupy Treblers, profesjonalnego zespołu specjalizującego
      się w tańcu irlandzkm. Widowiskowy step oraz delikatny taniec w miękkich
      butach przy akompaniamencie wspaniałej muzyki irlandzkiej - to właśnie
      prezentujemy na każdym z naszych pokazów.&nbsp;&nbsp;
      <span>CZYTAJ WIĘCEJ &gt;&gt;</span>
    </p>
  </div>
);
