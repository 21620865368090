const groupMembers = [
  {
    src: require("./Images/Gosia.jpg"),
    name: "Gosia"
  },
  {
    src: require("./Images/Marysia.jpg"),
    name: "Marysia"
  },
  {
    src: require("./Images/Lukasz.jpg"),

    name: "Łukasz"
  },
  {
    src: require("./Images/Ola.jpg"),
    name: "Ola"
  },
  {
    src: require("./Images/Maciek.jpg"),
    name: "Maciek"
  },
  {
    src: require("./Images/Misia.jpg"),
    name: "Michalina"
  },
  {
    src: require("./Images/Karolina.jpg"),
    name: "Karolina"
  },
  {
    src: require("./Images/Ania.jpg"),

    name: "Ania"
  },
  // {
  //   src: require("./Images/Grzesiek.jpg"),
  //   name: "Grzegorz"
  // },
  {
    src: require("./Images/Patrycja.jpg"),
    name: "Patrycja"
  },
  // {
  //   src: require("./Images/KasiaO.jpg"),
  //   name: "Kasia"
  // },
  {
    src: require("./Images/KasiaD.jpg"),
    name: "Kasia"
  },
  {
    src: require("./Images/Tosia.jpg"),
    name: "Tosia"
  }
];

export default groupMembers;
